/** @jsxImportSource theme-ui */
import { useGetAnnouncementListQuery } from '@/generated/graphql'
import { pxToRem } from '@/utils'
import { queryOptions } from '@/utils/queryOptions'
import { memo } from 'react'
import { Flex, Text } from 'theme-ui'

import AnnouncementItem from './AnnouncementItem'
import AnnouncementItemSkeleton from './AnnouncementItemSkeleton'

// 首頁公告顯示最大數量
const MAX_ANNOUNCEMENT_COUNT = 3

/**
 * @name 創作中心公告區塊
 */
const AnnouncementSection = () => {
  const announcementListQuery = useGetAnnouncementListQuery(
    {
      limit: MAX_ANNOUNCEMENT_COUNT,
    },
    { ...queryOptions.useGetAnnouncementListQuery }
  )

  return (
    <section>
      <Flex sx={{ height: pxToRem(64), alignItems: 'center' }}>
        <Text as="h2" variant="typography.headline.16">
          創作中心公告
        </Text>
      </Flex>

      <Flex sx={{ flexDirection: 'column' }}>
        {announcementListQuery.isSuccess ? (
          announcementListQuery.data.newsList.map(announcement => (
            <AnnouncementItem
              key={announcement.id}
              announcementId={announcement.id}
              headline={announcement.headline}
              deliveredTime={announcement.deliveredTime}
            />
          ))
        ) : (
          <AnnouncementItemSkeleton count={MAX_ANNOUNCEMENT_COUNT} />
        )}
      </Flex>
    </section>
  )
}

export default memo(AnnouncementSection)
