/** @jsxImportSource theme-ui */
import { pxToRem } from '@/utils'
import { Fragment, memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Flex, Image, Text } from 'theme-ui'

interface Props {
  count: number
}

const AnnouncementItemSkeleton = ({ count }: Props) => {
  return (
    <Fragment>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <Flex key={`announcement-skeleton-${index}`} p="3" sx={{ gap: 3 }}>
            <Box sx={{ width: pxToRem(24), height: pxToRem(24), flexShrink: 0 }}>
              <Image
                src="/static/images/Kadokado-notification-SYSTEM_16.svg"
                alt="announcement logo"
                sx={{ objectFit: 'contain' }}
              />
            </Box>
            <Flex sx={{ flexDirection: 'column', gap: 1, width: 'full' }}>
              <Text variant="typography.callout.14">
                <Skeleton />
              </Text>
              <Text variant="typography.caption.12">
                <Skeleton width={70} />
              </Text>
            </Flex>
          </Flex>
        ))}
    </Fragment>
  )
}

export default memo(AnnouncementItemSkeleton)
