/** @jsxImportSource theme-ui */
import { routes } from '@/constants/routes'
import { pxToRem } from '@/utils'
import dayjs from 'dayjs'
import Link from 'next/link'
import { memo } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

interface Props {
  headline: string
  deliveredTime: string
  announcementId: number
}

const AnnouncementItem = ({ announcementId, headline, deliveredTime }: Props) => {
  return (
    <Link
      href={{ pathname: routes.ANNOUNCEMENT, query: { announcementId } }}
      rel="noopener"
      sx={{ display: 'flex', pt: 3, px: 3, gap: 3 }}
    >
      <Box sx={{ width: pxToRem(24), height: pxToRem(24), flexShrink: 0 }}>
        <Image
          src="/static/images/Kadokado-notification-SYSTEM_16.svg"
          alt="announcement logo"
          sx={{ objectFit: 'contain' }}
        />
      </Box>

      <Flex
        sx={{
          flexDirection: 'column',
          flex: 1,
          gap: 1,
          pb: 3,
          borderBottomWidth: pxToRem(1),
          borderColor: 'gray.1',
          borderStyle: 'solid',
        }}
      >
        <Text variant="typography.callout.14" sx={{ variant: 'singleLineTruncation' }}>
          {headline}
        </Text>
        <Text variant="typography.caption.12" color="gray.5">
          {dayjs(deliveredTime).format('YYYY.MM.DD')}
        </Text>
      </Flex>
    </Link>
  )
}

export default memo(AnnouncementItem)
