/** @jsxImportSource theme-ui */
import { useGetMeQuery } from '@/api/query'
import { MemberKind } from '@/api/types'
import { MEMBER_KIND_CONFIG } from '@/constants/memberKind'
import dynamic from 'next/dynamic'
import { memo } from 'react'
import { FiStar } from 'react-icons/fi'
import { Box, Flex, Text } from 'theme-ui'

const ShortcutMenu = dynamic(() => import('./ShortcutMenu'))
const ProfileSectionSkeleton = dynamic(() => import('./ProfileSectionSkeleton'))
const MyAvatar = dynamic(() => import('@/components/Avatar/MyAvatar'))

const DEFAULT_MEMBER_KIND = MemberKind.Regular

/**
 * @name 作者資訊區塊
 */
const ProfileSection = () => {
  const meQuery = useGetMeQuery()

  if (meQuery.isLoading) return <ProfileSectionSkeleton />

  return (
    <Flex
      as="section"
      sx={{
        gap: 3,
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex sx={{ flexGrow: '1', alignItems: 'center', gap: 3 }}>
        {/* Avatar */}
        <MyAvatar size="xLarge" />

        <Box>
          {/* 作者名稱 */}
          <Text
            as="h2"
            variant="typography.title.20"
            sx={{ mb: 3, variant: 'singleLineTruncation' }}
          >
            {meQuery.data?.displayName}
          </Text>

          {/* 作者身份 */}
          <Flex
            variant="typography.body.14"
            sx={{
              alignItems: 'center',
              gap: 2,
              color: MEMBER_KIND_CONFIG[meQuery.data?.memberKind ?? DEFAULT_MEMBER_KIND].color,
            }}
          >
            <FiStar
              sx={{
                fontSize: 1,
                fill: MEMBER_KIND_CONFIG[meQuery.data?.memberKind ?? DEFAULT_MEMBER_KIND].color,
              }}
            />
            {MEMBER_KIND_CONFIG[meQuery.data?.memberKind ?? DEFAULT_MEMBER_KIND].label}
          </Flex>
        </Box>
      </Flex>
      {/* ====== 有作品，顯示快捷選單；無作品，顯示新增作品 ====== */}
      <ShortcutMenu />
    </Flex>
  )
}

export default memo(ProfileSection)
