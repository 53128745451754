/** @jsxImportSource theme-ui */
import { pxToRem } from '@/utils'
import { memo, ReactNode } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import { Box, Flex, Text } from 'theme-ui'

interface Props {
  prefixIcon: ReactNode
  iconLabel: string
  text: string
  isLoading: boolean
}

const OverviewItem = ({ prefixIcon, iconLabel, text, isLoading }: Props) => {
  return (
    <Flex
      sx={{
        p: 3,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: pxToRem(96),
        borderRadius: 'default',
        bg: 'gray.1',
        minWidth: pxToRem(226),
      }}
    >
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box variant="superCenter" sx={{ gap: 2, color: 'gray.8' }}>
          {prefixIcon}
          <Text variant="typography.callout.14">{iconLabel}</Text>
        </Box>

        <Box
          variant="typography.caption.12"
          sx={{ display: 'flex', gap: 2, alignItems: 'center', color: 'primary' }}
        >
          更多
          <FiChevronRight sx={{ fontSize: 2 }} />
        </Box>
      </Flex>

      <Text variant="typography.title.24" sx={{ alignSelf: 'center' }}>
        {isLoading ? <Skeleton width={32} /> : text}
      </Text>
    </Flex>
  )
}

export default memo(OverviewItem)
