/** @jsxImportSource theme-ui */
import { routes } from '@/constants/routes'
import { useGetMyOverviewQuery, useGetTotalStatsTotalOverallsQuery } from '@/api/query'
import { convertNumberToKmb, pxToRem, thousandSeparator } from '@/utils'
import dayjs from 'dayjs'
import Link from 'next/link'
import { memo, useMemo } from 'react'
import { FiDollarSign, FiEdit3, FiEye } from 'react-icons/fi'
import { TiHeartOutline } from 'react-icons/ti'
import { Flex, Text } from 'theme-ui'

import OverviewItem from './OverviewItem'
import { ampli } from '@/ampli'

/**
 * @name 本月總覽區塊
 */
const OverviewSection = () => {
  // 調整統計資料為提供至前一天的數值
  // https://kadokado.atlassian.net/browse/KAD-3485
  const yesterday = dayjs().subtract(1, 'day').endOf('day')

  const overviewQuery = useGetMyOverviewQuery()
  const totalStatTotalOverallsQuery = useGetTotalStatsTotalOverallsQuery()

  // 總覽的 items
  const overviewItems = useMemo(
    () => [
      {
        prefixIcon: <FiEdit3 />,
        label: '擁有章節數',
        text: convertNumberToKmb(overviewQuery.data?.totalChapterCount),
        href: routes.MY_BOOKS,
        onClick: () => ampli.bookAllButtonClicked({ 'all button location': '總覽' }),
      },
      {
        prefixIcon: <FiEye />,
        label: '歷史閱覽數',
        text: convertNumberToKmb(totalStatTotalOverallsQuery.data?.totalReadCount),
        href: routes.DATA_CENTER,
        onClick: () =>
          ampli.dataCenterButtonClicked({ 'data center button location': '歷史閱覽數' }),
      },

      {
        prefixIcon: <TiHeartOutline />,
        label: '歷史收藏數',
        text: convertNumberToKmb(totalStatTotalOverallsQuery.data?.totalFavoriteCount),
        href: routes.DATA_CENTER,
        onClick: () =>
          ampli.dataCenterButtonClicked({ 'data center button location': '歷史收藏數' }),
      },

      {
        prefixIcon: <FiDollarSign />,
        label: '累積收益',
        text: thousandSeparator(overviewQuery.data?.totalRevenue),
        href: routes.REVENUE_REPORT,
        onClick: () =>
          ampli.revenueReportButtonClicked({ 'revenue report button location': '總覽' }),
      },
    ],
    [overviewQuery.data, totalStatTotalOverallsQuery.data]
  )

  return (
    <section>
      <Flex sx={{ height: pxToRem(64), alignItems: 'center', justifyContent: 'space-between' }}>
        <Text as="h2" variant="typography.headline.16">
          總覽
        </Text>
        <Text variant="typography.caption.12" color="gray.6">
          統計至 {yesterday.format('YYYY.MM.DD')}
        </Text>
      </Flex>

      <Flex sx={{ flexDirection: ['column', 'column', 'column', 'row'], gap: 3 }}>
        {overviewItems.map(item => (
          <Link key={item.label} href={item.href} onClick={item?.onClick} rel="noopener">
            <OverviewItem
              prefixIcon={item.prefixIcon}
              iconLabel={item.label}
              text={item.text}
              isLoading={overviewQuery.isLoading}
            />
          </Link>
        ))}
      </Flex>
    </section>
  )
}

export default memo(OverviewSection)
